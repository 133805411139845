@use '../../partials/_variables.scss' as *;
@use '../../partials/_typography.scss' as *;
@use '../../partials/mixins' as *;

.header{
    background-color: #9EBFC0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0.75rem 1rem;
    height: 4.375rem;
    @include tablet{
        padding:0.75rem 1.5rem;
    }
    @include desktop{
        height: 4.8125rem;
    }
}
.logo-link{
    display: flex;
    
    &__image{
        width: 160px;
        object-fit: contain;
        @include desktop{
            width: 200px;
        }
    }
}
.menu-icon-container{
    cursor: pointer;
    padding: 0.75rem 0 0.75rem 0.375rem;
    @include tablet{
        display:none;
    }
}
.menu-icon{
    background: #ffffff;
    height: 0.0625rem;
    transition: background .2s ease-out;
    width: 1.325825214724777rem;
    position: relative;

    &::before{
        background: #ffffff;
        content: '';
        height: 0.0625rem;
        transition: all .2s ease-out;
        width: 1.325825214724777rem;
        position: absolute;
        top: 0.46875rem;
    }
    &::after{
        background: #ffffff;
        content: '';
        height: 0.0625rem;
        transition: all .2s ease-out;
        width: 1.325825214724777rem;
        position: absolute;
        top: -0.46875rem;
    }
    &--animate{
        background: transparent;

        &::before{
            transform: rotate(45deg);
            transform-origin: top right;
        }
        &::after{
            transform: rotate(-45deg);
            transform-origin: top right;
        }
    }

}
.nav-menu{
    display: flex;
    flex-direction: column;
    padding: 1.75rem 1rem 0 1rem;
    position: fixed;
    top: 4.375rem;
    right: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: flex-end;

    &--active{
        background: #9EBFC0;
        right: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
}
.nav-links{
    list-style: none;
    padding-bottom: 1.75rem;
}
.nav-link{
    text-decoration: none;
    list-style: none;
    font-family: "Avenir Next Regular";
    color: #ffffff;
    text-align: center;    
    width: 100%;
    transition: all 0.5s ease;
    padding-bottom: 0.25rem;
    position: relative;

    &--service{
        font-family: "Nomark";
        font-weight: 600;
    }

    &::after{
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 0.0625rem;
        left: 50%;
        position: absolute;
        background: #ffffff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover::after{
        width: 100%; 
        left: 0;
    }
}
.nav-bar-links{
    display: none;
    @include tablet{
        display: inline-block;
    }
    &__link{ 
        text-decoration: none;
        color: #ffffff;
        font-family: "Avenir Next Regular";
        margin-left: 2rem;
        position: relative;
        font-size: 1rem;
        padding: 0.25rem 0;
        @include desktop{
            font-size: 1.125rem;
        }

        &::after{
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 0.0625rem;
            left: 50%;
            position: absolute;
            background: #ffffff;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
        }
    
        &:hover::after{
            width: 100%; 
            left: 0;
        }

        &:hover .dropdown-menu{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}
.dropdown-menu{
    display: none;
    position: absolute;
    background-color: #9EBFC0;
    right: 0;
    top: 1.75rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.2);
    padding: 0.75rem 1rem;
    z-index: 1;

    &__link{
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
        font-family: "Nomark";
        white-space: nowrap;
        padding: 0.5rem 0;

        &:hover{
            background-color: #ffffff;
            color: #9EBFC0;
        }
    }
}