@use '../partials/_variables.scss' as *;
@use '../partials/_typography.scss' as *;
@use '../partials/mixins' as *;

.demographic{
    
    &__body{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &--format{
            max-width: 64rem;
        }

        &--personal-shopping {
            height: 100vh;
        }
    }
}
.page-title{
    padding: 1.5rem 0;
    text-align: center;
    font-family: "Avenir Next Regular";
    font-size: 1.75rem;
    @include tablet{
        font-size: 2.25rem;
        padding: 2rem 0; 
    }
    @include desktop{
        font-size: 3rem;
        padding: 2.5rem 0;
    }
}
.package-alt{
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;
    @include tablet{
        padding: 0 1.5rem 1.5rem 3rem;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
    @include desktop{
        padding: 0 3rem 3rem;
    }

    &__title{
        font-family: "Nomark";
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        @include tablet{
            display: none;
            font-size: 1.75rem; 
            margin-left: 0.75rem;
            margin-bottom: 0;
        }
        @include desktop{
            font-size: 2.25rem;
        }
    }
    &__custom{
        padding: 0.5rem 0 1rem;
        font-family: "Avenir Next Regular";
        font-size: 0.85rem;
        @include tablet{
            padding: 0 0 1rem;
            font-size: 0.925rem;
        }
        @include desktop{
            font-size: 1rem;
        }
    }
    &__contact-form-container{
        border-top: 0.0625rem solid #030303;
        @include tablet{
            border-left: 0.0625rem solid #030303; 
            border-top: none;
            padding-left: 0.75rem;
            margin-left: 0.75rem;
            width: 72%;
        }
        @include desktop{
            padding: 0 0 0 1.5rem;
            margin-left: 1.5rem;
        }
    }
}
.package-alt-title{
    display: none;
    @include tablet{
        display: flex;
        flex-direction: column;
    }
    &__text{
        font-family: "Nomark";
        font-size: 1.75rem; 
        @include tablet{
            text-align: right;
        }
        @include desktop{
            font-size: 2.25rem;
        }
    }
}
.package-alt-body{
    border-top: 0.0625rem solid #030303;
    padding: 0.5rem 0;
    font-family: "Avenir Next Regular";
    @include tablet{
        padding: 0 0 0 0.75rem;
        border-left: 0.0625rem solid #030303;
        border-top: none;
        margin-left: 0.75rem;
        width: 72%;
    }
    @include desktop{
        padding: 0 0 0 1.5rem;
        margin-left: 1.5rem;
    }

    &__rate{
        font-size: 1rem;
        font-weight: bold;
        @include tablet{
            text-align: left;
            font-size: 1.125rem; 
        }
        @include desktop{
            font-size: 1.25rem;
        }
    }
    &__description{
        padding: 1rem 0 0;
        font-family: "Avenir Next Regular";
        font-size: 0.85rem;
        @include tablet{
            font-size: 0.925rem; 
        }
        @include desktop{
            font-size: 1rem;
        }

        &--bold{
            font-weight: bold;
        }
    }
}