@font-face{
    font-family: "Avenir Next Regular";
    src: url("../assets/global-font/AvenirNextLTPro-Regular.otf");
    src: url("../assets/global-font/AvenirNextLTPro-Regular.woff") format("woff");
    src: url("../assets/global-font/AvenirNextLTPro-Regular.woff2") format("woff2");    
}  
@font-face{
    font-family: "Avenir Next Demi Bold";
    src: url("../assets/global-font/AvenirNextLTPro-Demi.otf");
    src: url("../assets/global-font/AvenirNextLTPro-Demi.woff") format("woff");
    src: url("../assets/global-font/AvenirNextLTPro-Demi.woff2") format("woff2");
} 
@font-face{
    font-family: "Avenir Next Bold";
    src: url("../assets/global-font/AvenirNextLTPro-Bold.otf");
    src: url("../assets/global-font/AvenirNextLTPro-Bold.woff") format("woff");
    src: url("../assets/global-font/AvenirNextLTPro-Bold.woff2") format("woff2");
}
@font-face{
    font-family: "Nomark";
    src: url("../assets/global-font/Nomark.otf");
}
@font-face{
    font-family: "Distant Stroke";
    src: url("../assets/global-font/Distant_Stroke.otf");
}
@font-face{
    font-family: "Distant Stroke Medium";
    src: url("../assets/global-font/Distant_Stroke_Medium.otf");
}

