@use '../../partials/_variables.scss' as *;
@use '../../partials/_typography.scss' as *;
@use '../../partials/mixins' as *;

.hero-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.hero-image-left{
    @include hero-image-sides{
        background-image: url('../../assets/photos/melissa/BP13.jpg');
        background-position: center;
        background-size: cover;
        height: 110vw;
        max-height: 500px;
        width: calc(100% - 782px);
        max-width: 368px;
    }
}
.hero-image-right{
    @include hero-image-sides{
        background-image: url('../../assets/photos/melissa/BP01.jpg');
        background-position: center;
        background-size: cover;
        height: 110vw;
        max-height: 500px;
        width: calc(100% - 782px);
        max-width: 368px;
    }
}
.hero-image{
    background-image: url('../../assets/photos/melissa/BP11-cropped.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 110vw;
    max-height: 500px;
    width: 100%;
    max-width: 770px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include hero-image-sides{
        margin: 0 6px;
        min-width: 770px;
    }    

    &__text{
        width: 80vw;
        max-width: 510px;
        max-height: 139.75px;
        @include hero-image-sides{
            min-width: 510px;            
        }
    }
}
.about-me{
    display: flex;
    justify-content: center;
}
.profile-container{
    padding: 1rem 1rem;
    @include tablet{
        padding: 1.5rem 3rem;
        display: flex;
        max-width: 770px;
    }
    @include desktop{
        padding: 2rem 0;
    }
}
.profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 0.0625rem solid #030303;
    @include tablet{
        border-bottom: none;
        border-right: 0.0625rem solid #030303;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

    &__title{
        font-family: "Distant Stroke Medium";
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1rem;
        color:#030303;
        @include tablet{
            font-size: 3.5rem;
            margin-bottom: 1.5rem;
        }
        @include desktop{
            font-size: 4rem;
        }
    }
}
.profile-frame{
    background-color: #7B9AA0;
    width: 215px;
    height: 215px; 
    text-align: center;
    vertical-align: center; 
    @include tablet{
        width: 231px;
        height: 231px;
    }
    @include desktop{
        width: 247px;
        height: 247px; 
    }  

    &__image{
        width: 200px;
        height: 200px;
        margin-top: 7.5px;
        object-fit: cover;
        object-position: 35% 0;
        @include tablet{
            width: 216px;
            height: 216px;
        }
        @include desktop{
            width: 232px;
            height: 232px; 
        }

    }
}
.profile-bio{
    padding: 2rem 1rem 1rem;
    @include tablet{
        padding: 4.5rem 0 2rem 1.5rem;
    }
    &__text{
        font-size: 0.85rem;
        font-family: "Avenir Next Regular";
        color:#030303;
        @include tablet{
            font-size: 0.925rem;
        }
        @include desktop{
            font-size: 1rem;
        }
    }
}
.services{
    background-color: #DCDFE0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0 2rem;
    @include tablet{
        padding: 1.5rem 0 3rem;
    }
    @include desktop{
        padding: 2rem 0 4rem;
    }

    &__title{
        font-family: "Distant Stroke Medium";
        font-size: 3rem;
        line-height: 3rem;
        color:#030303;
        margin-bottom: 1rem;
        @include tablet{
            font-size: 3.5rem;
            margin-bottom: 1.5rem;
        }
        @include desktop{
            font-size: 4rem;
        }
    }
}
.services-cards{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__background{
        position: absolute;
        background-color: #0D3530;
        height: 80%;
        width: 100%;
        top: 10%;
        left: 0;
    }
}
.services-cards-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 0.5rem;
    position: relative;
    max-width: 770px;
    @include desktop{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0
    }
}
.services-cards-top{
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    @include desktop{
        width: 50%;
        justify-content: space-between;
        padding-right: 1.02083333333333333rem;
    }
}
.services-card{
    width: 8.5rem;
    height: 8.5rem;
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: 0.3s ease;
    text-decoration: none;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
    @include tablet{
        margin: 0 0.75rem;
        width: 9.5rem;
        height: 9.5rem;
    }
    @include desktop{
        margin: 0;
        width: 10.5rem;
        height: 10.5rem;
    }
    &:hover{
        transform: scale(1.03);
    }

    
}
.card-image-1{
    background-image: url('../../assets/photos/roses/_DSF3445-min.jpg');
    background-size: 115%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 15%; 
    height: 60%;
  
}
.card-image-2{
    background-image: url('../../assets/photos/roses/_DSF3329-min.jpg');
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 1%; 
    height: 60%;
}
.card-text{
    background-color: #ffffff;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include tablet{
        padding: 0 1rem;
    }

    &__style{
        font-size: 1rem;
        font-family: "Nomark";
        color:#030303;
        @include desktop{
            font-size: 1.25rem;
        }
    }
}
.services-cards-bottom{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
    z-index: 1;
    @include tablet{
        margin-top: 1.5rem;
    }
    @include desktop{
        margin: 0;
        width: 50%;
        justify-content: space-between;
        padding-left: 1.02083333333333333rem;
    }
}
.card-image-3{
    background-image: url('../../assets/photos/roses/_DSF3248-min.jpg');
    background-size: 170%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 50%; 
    height: 60%;   
}
.card-image-4{
    background-image: url('../../assets/photos/roses/_DSF3285-min.jpg');
    background-size: 130%;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 50%; 
    height: 60%;
}
.contact-me{
    padding: 1rem 1rem;
    @include tablet{
        padding: 1.5rem 3rem;
        display: flex;
        justify-content: center;
    }
    @include desktop{
        padding: 2rem 4rem;
    }
}
.contact-container{
    
    @include tablet{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        max-width: 770px;
    }
}
.contact-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 0.0625rem solid #030303;
    margin-bottom: 1rem;
    @include tablet{
        border-bottom: none;
        border-left: 0.0625rem solid #030303;
        margin-bottom: 0;
    }
    
    &__title{
        font-family: "Distant Stroke Medium";
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 2rem;
        color:#030303;
        @include tablet{
            font-size: 3.5rem;
            margin-bottom: 2.75rem;
        }
        @include desktop{
            font-size: 4rem;
        }
    }    
}
.contact-profile-frame{
    position: relative;
    background-color: #7B9AA0;
    width: 200px;
    height: 200px; 
    text-align: center;
    vertical-align: center; 
    @include tablet{
        width: 216px;
        height: 216px; 
        margin-left: 40px;   
    }
    @include desktop{
        width: 232px;
        height: 232px;
    }  

    &__image{
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 200px;
        height: 200px;
        object-fit: cover;
        object-position: 35% 55%;      
        @include tablet{
            width: 216px;
            height: 216px;
        }
        @include desktop{
            width: 232px;
            height: 232px;
        } 
    }
}
.contact-client{
    padding: 0 1rem;
    @include tablet{
        padding: 0 1.5rem 1.5rem 0;
    }
    
    &__text{
        font-family: "Avenir Next Regular";
        font-size: 0.85rem;
        margin-bottom: 1rem;
        color:#030303;
        @include tablet{
            font-size: 0.925rem;
        }
        @include desktop{
            font-size: 1rem;
        }
    }
}