@use '../../partials/_variables.scss' as *;
@use '../../partials/_typography.scss' as *;
@use '../../partials/mixins' as *;

.footer{
    background-color: #9EBFC0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:0.75rem 1rem;
    @include mobile-to-tablet{
        flex-direction: row-reverse;       
    }
    @include tablet{
        padding:1.125rem 1.5rem; 
    }
}
.social-links{
    margin-bottom: 0.75rem;
    height: 1.5rem;
    @include mobile-to-tablet{
        margin-bottom: 0;
    }
    @include tablet{
        height: 2rem;
    }
    @include desktop{
        height: 2.5rem;
    }
}
.social{
    height: 1.5rem;
    margin-left: 0.75rem;
    @include tablet{
        height: 2rem;
        margin-left: 1rem;
    }
    @include desktop{
        height: 2.5rem;
        margin-left: 1.5rem;
    }

    &__icon{
        height: 1.5rem;
        @include tablet{
            height: 2rem;
        }
        @include desktop{
            height: 2.5rem;
        }
    }
}
.footer-text{
    display: flex;
    flex-direction: column;
    @include tablet{
        flex-direction: row;
    }
    
    &--format{
        color: #ffffff;
        font-family: "Avenir Next Regular";
        font-size: 0.6rem;
        padding-bottom: 0.125rem;
        margin-right: 0.15625rem;
        @include tablet{
            font-size: 0.65rem;
            padding: 0;
        }
        @include desktop{
            font-size: 0.7rem;
        }
    }
}
.steve-link{
    text-decoration: none;
    color: #ffffff;
    position: relative;

    &::after{
        background: none repeat scroll 0 0 transparent;
        bottom: 2;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #ffffff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover::after{
        width: 100%; 
        left: 0;
    }
}
