@use '../../partials/_variables.scss' as *;
@use '../../partials/_typography.scss' as *;
@use '../../partials/mixins' as *;

.contact-form{
    display: flex;
    flex-direction: column;

    &__label{
        font-family: "Avenir Next Demi Bold";
        font-size: 0.85rem;
        line-height: 0.85rem;
        margin-bottom: 0.5rem;
        @include desktop{
            font-size: 0.925rem;
        }
    }
    &__info{
        font-family: "Avenir Next Regular";
        background-color: #DCDFE0;
        border: none;
        padding: 0.25rem 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 0.85rem;
        border-radius: 0;
        @include mobile-to-tablet{
            width: 65%;
        }
        @include tablet{
            font-size: 0.925rem; 
        }
        @include desktop{
            font-size: 1rem;
        }

        &::placeholder{
            color: #030303;
            font-family: "Avenir Next Regular";
            font-size: 0.85rem;
            @include tablet{
                font-size: 0.925rem;
            }
            @include desktop{
                font-size: 1rem;
            }
        }
        &:focus{
            outline: none;
            border: 0.125rem solid #030303;
        }
    }
    &__message{
        font-family: "Avenir Next Regular";
        background-color: #DCDFE0;
        border: none;
        padding: 0.25rem 0.5rem;
        font-size: 0.85rem;
        resize: none;
        height: 6rem;
        margin-bottom: 0.5rem;
        border-radius: 0;
        @include tablet{
            font-size: 0.925rem;
            height: 6.25rem;
        }
        @include desktop{
            font-size: 1rem;
        }

        &::placeholder{
            color: #030303;
            font-family: "Avenir Next Regular";
            font-size: 0.85rem;
            @include tablet{
                font-size: 0.925rem;
            }
            @include desktop{
                font-size: 1rem;
            }
        }
        &:focus{
            outline: none;
            border: 0.125rem solid #030303;
        }
    }
    &__submit{
        background-color: #030303;
        color: #ffffff;
        border: none;
        font-family: "Avenir Next Demi Bold";
        padding: 0.25rem 0.5rem;
        font-size: 0.85rem;
        @include mobile-to-tablet{
            width: 33.3333333333%;
        }
        @include tablet{
            font-size: 0.925rem;
        }
        @include desktop{
            font-size: 1rem;
        } 
    }
    .errors-text{
        font-family: "Avenir Next Regular";
        color: red;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
        @include tablet{
            font-size: 0.825rem;
        }
        @include desktop{
            font-size: 0.9rem;
        }
    }
    .success-text{
        font-family: "Avenir Next Regular";
        color: #39C16C;
        font-size: 0.75rem;
        margin-top: 0.5rem;
        @include tablet{
            font-size: 0.825rem;
        }
        @include desktop{
            font-size: 0.9rem;
        }
    }
    .input-error{
        border: 2px solid red;
    }
}