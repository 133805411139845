@use './variables' as *;

@mixin mobile-to-tablet {
    @media (min-width: $mobile-to-tablet-breakpoint){
        @content;
    }
}
@mixin tablet {
    @media (min-width: $tablet-breakpoint){
        @content;
    }
}
@mixin hero-image-sides {
    @media (min-width: $hero-image-sides-breakpoint){
        @content;
    }
}
@mixin desktop {
    @media (min-width: $desktop-breakpoint){
        @content;
    }
}